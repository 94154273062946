import * as React from "react";
import { useEffect, useState } from "react";
import "./DownloadPanel.css";
import {
  generateDownloadFileLink,
  generatePartOfGridForCoordinates,
  generatePartOfGridForIndex,
} from "../../service/api-service";
import LoadingButton from "../loadingbutton/LoadingButton";
import axios from "axios";
import Spinner from "../spinner/Spinner";
import DOMPurify from "dompurify";

const DownloadPanel = (props: any) => {
  const layerType: string = props.layerType;
  const completeVersions = props.completeVersions;
  const displaySelect = props.displaySelect;
  const rectangleBound = props.rectangleBound;
  const index = props.index;
  const validWest = props.validWest;
  const validEast = props.validEast;
  const validSouth = props.validSouth;
  const validNorth = props.validNorth;
  const [displayDownloadPanel, setDisplayDownloadPanel] = [
    props.displayDownloadPanel,
    props.setDisplayDownloadPanel,
  ];

  const [type, setType] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const [downloadPartGridIsLoading, setDownloadPartGridIsLoading] =
    useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionIsLoading, setDescriptionIsLoading] = useState(false);
  const [versionMessageFail, setVersionMessageFail] = useState("");
  const [descriptionMessageFail, setDescriptionMessageFail] = useState("");
  const [downloadMessageFail, setDownloadMessageFail] = useState("");
  const [partDownloadMessageSuccess, setPartDownloadMessageSuccess] =
    useState("");
  const [partDownloadMessageFail, setPartDownloadMessageFail] = useState("");

  useEffect(() => {
    setType("ANOMALY_MAP");
    if (completeVersions.length > 0) setVersion(completeVersions[0]);
  }, [completeVersions]);

  useEffect(() => {
    if (displaySelect) setType("GRID");
  }, [displaySelect]);

  useEffect(() => {
    if (version && version != "") {
      setDescriptionIsLoading(true);
      generateDownloadFileLink(getDescriptionByType(type), version)
        .then((url) => {
          axios
            .get(url)
            .then((result: any) => {
              setDescription(result.data);
              setDescriptionIsLoading(false);
            })
            .catch(() => {
              setDescriptionIsLoading(false);
              setDescriptionMessageFail("Download description fails.");
            });
        })
        .catch(() => {
          setDescriptionIsLoading(false);
          setVersionMessageFail("Download version fails.");
        });
    }
  }, [type, version, displaySelect, displayDownloadPanel]);

  useEffect(() => {
    setVersionMessageFail("");
    setDescriptionMessageFail("");
    setDownloadMessageFail("");
    setPartDownloadMessageSuccess("");
    setPartDownloadMessageFail("");
    setDescriptionIsLoading(true);
  }, [displaySelect, displayDownloadPanel]);

  const getDescriptionByType = (type: string) => {
    switch (type) {
      case "ANOMALY_MAP":
        return "ANOMALY_MAP_DESCRIPTION";
      case "INDEX_MAP":
        return "INDEX_MAP_DESCRIPTION";
      case "GRID":
        return "GRID_DESCRIPTION";
      case "MODEL":
        return "MODEL_DESCRIPTION";
      default:
        return "";
    }
  };

  const buildFilename = (type: string, version: string) => {
    switch (type) {
      case "ANOMALY_MAP":
        return `anomalymap-${version}.jpeg`;
      case "INDEX_MAP":
        return `indexmap-${version}.jpeg`;
      case "GRID":
        return `grid-${version}.asc`;
      case "MODEL":
        return `model-${version}.out.gz`;
      default:
        return "";
    }
  };

  const downloadFile = (type: string, version: string) => {
    if (version && version != "") {
      generateDownloadFileLink(type, version)
        .then((url) => {
          const link = document.createElement("a");
          link.download = buildFilename(type, version);
          link.href = url;
          link.click();
        })
        .catch(() => setDownloadMessageFail("Download fails."))
        .finally(() => setDownloadIsLoading(false));
    }
  };

  const onDownloadSubmit = () => {
    setDownloadIsLoading(true);
    downloadFile(type, version);
  };

  const downloadPartFile = () => {
    if (layerType == "ANOMALY_LAYER") {
      const west = rectangleBound.getWest();
      const east = rectangleBound.getEast();
      const south = rectangleBound.getSouth();
      const north = rectangleBound.getNorth();
      if (version != "") {
        generatePartOfGridForCoordinates(west, east, south, north, version)
          .then(() =>
            setPartDownloadMessageSuccess(
              "Download was scheduled. You will receive an email with the part grid attached (it could be in your spam box)."
            )
          )
          .catch(() => setPartDownloadMessageFail("Download fails."))
          .finally(() => setDownloadPartGridIsLoading(false));
      }
    } else {
      if (version != "") {
        generatePartOfGridForIndex(index, version)
          .then(() =>
            setPartDownloadMessageSuccess(
              "Download was scheduled. You will receive an email with the part grid attached (it could be in your spam box)."
            )
          )
          .catch(() => setPartDownloadMessageFail("Download fails."))
          .finally(() => setDownloadPartGridIsLoading(false));
      }
    }
  };

  const onDownloadPartGridSubmit = () => {
    setDownloadPartGridIsLoading(true);
    downloadPartFile();
  };

  return (
    displayDownloadPanel && (
      <div className="download-panel panel">
        <button
          className="panel-close"
          onClick={() => setDisplayDownloadPanel(false)}
        >
          &times;
        </button>
        <div className="download-panel-content">
          {!displaySelect && (
            <div className="inside-download-panel-container">
              <div className="inside-download-panel-header">
                <div className="inside-download-panel-form">
                  <label htmlFor="type">Type:</label>
                  <select
                    className="panel-select"
                    name="type"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="ANOMALY_MAP">Anomaly Map</option>
                    <option value="INDEX_MAP">Index Map</option>
                    <option value="GRID">Grid</option>
                    <option value="MODEL">Model</option>
                  </select>
                  <label htmlFor="version">Version:</label>
                  <select
                    className="panel-select"
                    name="version"
                    id="version"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    disabled={version == ""}
                  >
                    {completeVersions.map((version: string) => {
                      return <option value={version}>{version}</option>;
                    })}
                  </select>
                </div>
                <div className="inside-download-panel-button">
                  <LoadingButton
                    disabled={version == ""}
                    onClick={onDownloadSubmit}
                    isLoading={downloadIsLoading}
                  >
                    Download
                  </LoadingButton>
                </div>
              </div>
              {descriptionIsLoading && (
                <div className="inside-download-panel-loading">
                  <Spinner />
                </div>
              )}
              {downloadMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">{downloadMessageFail}</span>
                </div>
              )}
              {!descriptionIsLoading && versionMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">{versionMessageFail}</span>
                </div>
              )}
              {!descriptionIsLoading && descriptionMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">
                    {descriptionMessageFail}
                  </span>
                </div>
              )}
              {!descriptionIsLoading &&
                description != "" &&
                descriptionMessageFail == "" && (
                  <div
                    className="inside-download-panel-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(description),
                    }}
                  />
                )}
            </div>
          )}
          {displaySelect && (
            <div className="inside-download-panel-container">
              <div className="inside-download-panel-header">
                <div className="inside-download-panel-form-select">
                  <label htmlFor="version">Version:</label>
                  <select
                    className="panel-select"
                    name="version"
                    id="version"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    disabled={version == ""}
                  >
                    {completeVersions.map((version: string) => {
                      return <option value={version}>{version}</option>;
                    })}
                  </select>
                </div>
                <div className="inside-download-panel-button">
                  <LoadingButton
                    disabled={
                      version == "" ||
                      (layerType == "ANOMALY_LAYER" &&
                        (!validEast ||
                          !validWest ||
                          !validSouth ||
                          !validNorth))
                    }
                    onClick={onDownloadPartGridSubmit}
                    isLoading={downloadPartGridIsLoading}
                  >
                    Download Grid
                  </LoadingButton>
                </div>
              </div>
              {descriptionIsLoading && (
                <div className="inside-download-panel-loading">
                  <Spinner />
                </div>
              )}
              {partDownloadMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">
                    {partDownloadMessageSuccess}
                  </span>
                </div>
              )}
              {partDownloadMessageSuccess != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-success">
                    {partDownloadMessageSuccess}
                  </span>
                </div>
              )}
              {!descriptionIsLoading && versionMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">{versionMessageFail}</span>
                </div>
              )}
              {!descriptionIsLoading && descriptionMessageFail != "" && (
                <div className="inside-download-panel-description">
                  <span className="message-error">
                    {descriptionMessageFail}
                  </span>
                </div>
              )}
              {!descriptionIsLoading && description != "" && (
                <div
                  className="inside-download-panel-description"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default DownloadPanel;
