import * as React from "react";
import "./Information.css";
// @ts-ignore
import iagalogo from "../../logo/IAGA.jpg";
// @ts-ignore
import ccgmlogo from "../../logo/CCGM.png";
// @ts-ignore
import datacontrib20 from "../../pdf/WDMAM20_data_contrib.pdf";
// @ts-ignore
import datacontrib22 from "../../pdf/WDMAM22_data_contrib.pdf";

const Information = () => {
  return (
    <div className="information-container">
      <span className="title">Informations</span>
      <div className="p">
        The WDMAM (World Digital Magnetic Anomaly Map) is an international
        scientific project under the auspices of IAGA (International Association
        of Geomagnetism and Aeronomy) and CGMW (Commission for the Geological
        Map of the World), aiming to compile and make available magnetic
        anomalies caused by the Earth lithosphere, on continental and oceanic
        areas, in a comprehensive way, all over the World.
      </div>
      <div className="p">
        The project started in 2003 and resulted in a first version of the map
        (Korhonen et al., 2007). A call for candidates initiated in 2010 led to
        the building of a new map which, after evaluation and correction, was
        released at the IUGG General Assembly of Prag in June 2015 as WDMAM
        version 2.0. An improved version, built with the same methodology and
        additional data, was released at the IUGG General Assembly of Berlin in
        2023 as WDMAM version 2.1. This web site aims to distribute freely and
        as widely as possible a provisional version of the map (in jpeg format),
        the full grid or parts of the grid (in ASCII) to interested scientists
        and the general public. A printed version of the version 2.1 may be
        released by CGMW in a near future. A paper describing the building of
        the version 2.0 was published (Lesur et al., EPS, 2016).{" "}
      </div>
      <div className="p">
        Please cite version 2.0 as: Dyment, J., Lesur, V., Hamoudi, M., Choi,
        Y., Thebault, E., Catalan, M., the WDMAM Task Force*, the WDMAM
        Evaluators**, and the WDMAM Data Providers**, World Digital Magnetic
        Anomaly Map version 2.0, map available at http://www.wdmam.org.
      </div>
      <div className="p">
          Please cite version 2.2 as: Choi, Y., Dyment, J.,
          Lesur, V., Garcia Reyes, Catalan, M., Ishihara, T.,
          Litvinova, T., Hamoudi, M., the WDMAM Task Force*,
          and the WDMAM Data Providers**, World Digital Magnetic
          Anomaly Map version 2.2, map available at
          http://www.wdmam.org.
      </div>
      <div className="p">
        * the WDMAM Task Force: J. Dyment (chair), M. Catalan (co-chair), A. de
        Santis, M. Hamoudi, T. Ishihara, J. Korhonen, V. Lesur, T. Litvinova, J.
        Luis, B. Meyer, P. Milligan, M. Nakanishi, S. Okuma, M. Pilkington, M.
        Purucker, D. Ravat, E. Thébault. (alphabetical order)
      </div>
      <div className="p">
        ** the WDMAM Evaluators: C. Gaina, J. Luis, S. Maus, B. Meyer, M.
        Nakanishi, M. Purucker, Y. Quesnel, R. Saltus, P. Taylor. (alphabetical
        order)
      </div>
        <div className="p">
            The WDMAM Data Contributors for WDMAM 2.0 {" "}
            <a className="a" download href={datacontrib20}>
                here
            </a>
            {" "} and for WDMAM 2.2 {" "}
            <a className="a" download href={datacontrib22}>
                here
            </a>
        </div>
        <div className="information-img-item">
            <a href="http://www.iugg.org/IAGA/">
          <img className="img" src={iagalogo} alt="Iaga Logo" />
        </a>
        <a href="http://ccgm.org/">
          <img className="img" src={ccgmlogo} alt="CCGM Logo" />
        </a>
      </div>
      <div className="p">
        The WDMAM Web Application was designed by Basile Dyment.
      </div>
    </div>
  );
};
export default Information;
